import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const bonbonchaCustomerGroup = 'bonboncha';
export const customerGroup = bonbonchaCustomerGroup;

const supportEmail = {
	bonboncha: 'bonboncha@smoothr.de',
};
const firebaseConfig = {
	bonboncha: {
		apiKey: "AIzaSyAZC5JYBL0w8UnFdwCviTnkynqSJbZbz4A",
		authDomain: "bonboncha-a6a39.firebaseapp.com",
		projectId: "bonboncha-a6a39",
		storageBucket: "bonboncha-a6a39.appspot.com",
		messagingSenderId: "657599237175",
		appId: "1:657599237175:web:e3b79c551398cc600883b2",
		measurementId: "G-J6830YW8ZR"
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDVNB4lGjsTqyyPG3Z4n6oLDiDoMMC2C-8',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
};

// import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';
// import 'zone.js/dist/zone-error';

// export const sushisupplyCustomerGroup = 'sushisupply';
// export const customerGroup = sushisupplyCustomerGroup;

// const supportEmail = {
// 	sushisupply: 'moschmosch@smoothr.de',
// };
// const firebaseConfig = {
// 	sushisupply: {
// 		apiKey: "AIzaSyAcEE0JJJM0MQnUaBkMcuVynB9KPF7cK1g",
// 		authDomain: "sushisupply-8e8d9.firebaseapp.com",
// 		projectId: "sushisupply-8e8d9",
// 		storageBucket: "sushisupply-8e8d9.appspot.com",
// 		messagingSenderId: "77903306877",
// 		appId: "1:77903306877:web:18d9493e9a4c0430865114",
// 		measurementId: "G-8Q6BRW0T7Z"
// 	},
// };
// export const environment = {
// 	customerGroup,
// 	webAppUrl: window.location.protocol + '//' + window.location.host,
// 	placesApiKey: 'AIzaSyC1Ft6kZ0fniOtKF7lURbqn6Ba3uvByJw0',
// 	SUPPORT_EMAIL: supportEmail[customerGroup],
// 	firebaseConfig: firebaseConfig[customerGroup],
// 	countryList: ['de'],
// 	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
// 	...apiEnvironment,
// };

