import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const bonbonchaCustomerGroup = 'bonboncha';
export const customerGroup = bonbonchaCustomerGroup;

const supportEmail = {
	bonboncha: 'bonboncha@smoothr.de',
};
const firebaseConfig = {
	bonboncha: {
		apiKey: "AIzaSyAZC5JYBL0w8UnFdwCviTnkynqSJbZbz4A",
		authDomain: "bonboncha-a6a39.firebaseapp.com",
		projectId: "bonboncha-a6a39",
		storageBucket: "bonboncha-a6a39.appspot.com",
		messagingSenderId: "657599237175",
		appId: "1:657599237175:web:a3fbd2672c44f29e0883b2",
		measurementId: "G-5RTDPLL1QH"
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAPvS4kNQv0Wh05xgByamooXT-vJuSPVdI',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
};
