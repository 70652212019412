import { SignInPage } from './../sign-in/sign-in.page';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import validator from 'validator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AccountPage} from '../account/account.page';
import {EmailConfirmationPage} from '../email-confirmation/email-confirmation.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {environment} from '../../../environments/environment';
import {Gender} from 'src/smoothr-web-app-core/enums/Gender';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {AuthStrategy} from '../../../smoothr-web-app-core/models/AuthStrategy';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';


@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['sign-up.page.scss']
})
export class SignUpPage implements OnDestroy, OnInit {
	static url = 'sign-up';
	private onDestroy$ = new Subject();
	showRePassword = false;
	showPassword = false;
	loading = false;

	Gender = Gender;
	gender = 'gender';
	fullName = 'fullName';
	email = 'email';
	agbChecked = 'agbChecked';
	password = 'password';
	rePassword = 'rePassword';
	date = 'date';
	month = 'month';
	year = 'year';
	

	public loading$ = new BehaviorSubject(false);
	public signUpForm: FormGroup;
	private readonly subscription: Subscription;

	constructor(
		public translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private fb: FormBuilder
	) {
		if (repository.customer && !repository.customer.isAnonymous) {
			AccountPage.navigate(router);
		}
	}

	static navigate(router: Router) {
		router.navigateByUrl(SignUpPage.url);
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
		this.onDestroy$.next();
	}

	ngOnInit() {
		this.createFormSignUp();
	}
	
	createFormSignUp() {
		const formControlNames: any = {}

		// formControlNames[this.gender] = ['', [
		// 	Validators.required
		// ]];

		formControlNames[this.fullName] = ['',
			[
				Validators.required, 
				Validators.pattern(/\w+\s\w+/),
				 ]
		]
		formControlNames[this.email] = ['', [
			Validators.required,
			Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")	
		]];

		formControlNames[this.agbChecked] = [false, [
			Validators.required, Validators.requiredTrue
		]];
		
		formControlNames[this.password] = ['', [
			Validators.required, 
			Validators.minLength(8), 
			Validators.pattern(/\d+/), 
			Validators.pattern(/[A-Z]+/)
		]];

		formControlNames[this.rePassword] = ['', [
			Validators.required, 
			this._isPasswordMatch()
		]];

		// formControlNames[this.date] = ['', [
		// 	Validators.required, Validators.max(31)
		// ]];
		// formControlNames[this.month] = ['', [
		// 	Validators.required, Validators.max(12)
		// ]];
		// formControlNames[this.year] = ['', [
		// 	Validators.required
		// ]];


		this.signUpForm = this.fb.group(formControlNames);
	}

	private _isPasswordMatch(): ValidatorFn {
    return () => {
      const pass = this.signUpForm?.get('password')?.value;
      const confirmPass = this.signUpForm?.get('rePassword')?.value;
      return pass === confirmPass ? null : { notSame: true };
    };
  }

  isValid(controlName?: string): boolean {
		if(controlName) {
			return this.signUpForm.get(controlName)?.hasError('required') && this.signUpForm.get(controlName)?.touched;
		}

		return this.signUpForm.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if(controlName) {
			
			return  this.signUpForm.get(controlName)?.errors && !this.signUpForm.get(controlName)?.hasError('required');
		}
		return this.signUpForm.invalid;
	}
	

	getControlValue(controlName?: string) {
		if(controlName) {
			return this.signUpForm?.get(controlName)?.value;
		}
		return '';
	}

	async signUp() {
		this.signUpForm.markAllAsTouched();
		if(this.repository.customer.isAnonymous) {
			this.loading$.next(true);
			try {
				await Api.signUpWithCredentials(
					this.getControlValue('fullName'),
					this.getControlValue('gender'),
					this.getControlValue('email'),
					this.getControlValue('password'),
					[]
				);
				this.loading$.next(false);
				await EmailConfirmationPage.navigate(this.router, true);
			} catch (e) {
	
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.signUpForm.controls['email'].setErrors({incorrect: true});	
				}
				this.loading$.next(false);
			}
		}
		
	}
	

	signIn() {
		SignInPage.navigate(this.router);
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Google');
				const result = await Api.signIn(
					AuthStrategy.GOOGLE,
				);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(
					AuthStrategy.FACEBOOK,
				);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(
					AuthStrategy.APPLE,
				);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	openTerms() {
		window.open('/tos', '_blank');
	}

	openPrivacy() {
		window.open('/privacy', '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}

	gotoNextField(nextElement) {
		nextElement.setFocus();
	}
}
