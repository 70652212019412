import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {Api} from '../../../smoothr-web-app-core/api/api';
import Order from '../../../smoothr-web-app-core/models/Order';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {SignUpPage} from '../../pages/sign-up/sign-up.page';
import {Router} from '@angular/router';
import {
	axiosErrorToMessage,
	numberToCurrency
} from '../../../smoothr-web-app-core/utils/utils';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { log } from 'console';

@Component({
	selector: 'app-promo-code',
	templateUrl: './promo-code.component.html',
	styleUrls: ['promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit, OnDestroy {
	@Input() discount = '';
	@Output()
	error = new EventEmitter<string>();
	@Output()
	success = new EventEmitter<Order>();
	@Output()
	deletePromo = new EventEmitter<Order>();
	input: string;
	showInput = false;
	loading = false;
	private _error: string;
	private deletePromoSubscription: Subscription;

	constructor(
		private repository: RepositoryService,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		private router: Router
	) {}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(order: Order) {
		this._order = order;
		if (this._order && this._order.promoCode && this._order.promoCode.code) {
			this.input = this._order.promoCode.code;
		}
	}

	get promoError(): string {
		return this._error;
	}

	set promoError(value: string) {
		this._error = value;
		if (this._error) {
			this.loading = false;
			this.error.emit(value);
		}
	}

	ngOnInit() {
		this.deletePromoSubscription = this.deletePromo.subscribe(
			() => (this.promoError = null)
		);

	}

	ngOnDestroy() {
		this.deletePromoSubscription.unsubscribe();
		this.deletePromoSubscription = null;
	}

	async checkPromoCode() {
		if (!this.input || this.input.replace(' ', '').length === 0) {
			this.promoError = this.translate.instant('promo_code.not_valid');
			return;
		}
		this.loading = true;
		this.promoError = null;
		try {
			const promoCode = (
				await Api.getPromoCode({
					code: this.input,
					venue: this.order.venue
				})
			).data;
			promoCode.mov = +promoCode.mov;
			if (
				OrderUtils.orderTotalPrice(this.order, false, false) < promoCode.mov
			) {
				this.promoError = this.translate.instant('promo_code.mov_not_reached', {
					mov: numberToCurrency(promoCode.mov, this.order.currency)
				});
				return;
			}
			try {
				try {
					const orderCopy = JSON.parse(JSON.stringify(this.order));
					orderCopy.promoCode = promoCode;
					orderCopy.userUid = this.repository.customer.uid;
					orderCopy.orderAt = null;
					await Api.applyPromoCode(orderCopy);
				} catch (e) {
					this.promoError = axiosErrorToMessage(this.translate, e);
					return;
				}
				this.success.emit(
					OrderUtils.applyPromo(
						this.translate,
						this.repository._venue,
						this.order,
						promoCode,
						this.analytics
					)
				);
				this.loading = false;
				this.showInput = false;
			} catch (e) {
				this.promoError = e;
				this.loading = false;
			}
		} catch (e) {
			this.loading = false;
			if (e.response.status === 404) {
				this.promoError = this.translate.instant('promo_code.not_valid');
				return;
			}
			this.promoError = axiosErrorToMessage(this.translate, e);
			return;
		}

		console.log(this.order.promoCode)
	}

	resetError() {
		if (this._error) {
			this.promoError = null;
		}
	}

	editPromo() {
		const promoCode = this.order.promoCode;
		this.order = OrderUtils.removePromo(this.order);
		if (promoCode && promoCode.code) {
			this.order.promoCode = promoCode.code;
		}
		this.showInput = true;
	}

	removePromoAndEmit() {
		this.input = '';
		if (this.order) {
			this.deletePromo.emit(OrderUtils.removePromo(this.order));
		}
	}

	onAddClick() {
		this.showInput = true;
		this.cdr.detectChanges();
	}

	openLogin() {
		SignInPage.navigateWithParams(this.router, {promo: true});
	}

	openReg() {
		SignUpPage.navigate(this.router);
	}
}
